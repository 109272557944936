import React from "react";

const Page = () => {
    return (
        // Page Layout
        <>
            About
        </>
    )
}

export default Page