import React from "react"

const Vision = () => {
    return (
        <section id="vision">
            <div style={{textAlign:"center"}}>
                <h2 style={{margin:'15px auto'}}>Design for the Society, Make for the Future.</h2>
                <p style={{maxWidth:800}}>
                    <span style={{display:"inline-block"}}>장(張 : 베풀장)은 활을 길게 늘이는 어른의 모습으로, <wbr/>'늘이다', '베풀다' 라는 의미를 갖는 글자입니다. </span>
                    <wbr/>
                    <span style={{display:"inline-block"}}>베풀장은 디자인과 미래 기술을 활용해 <wbr/>우리 사회가 가진 문제를 해결합니다.</span>
                </p>
            </div>
        </section>
    )
}

export default Vision