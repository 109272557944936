import React from "react";
import Vision from "../sections/Vision";
import Topic from "../sections/Topic";


const Main = () => {
    
    const getImageUrl = (filename) => {
        const imageUrl = require('../assets/imgs/'+filename);
        return imageUrl;
    }

    return (
        // Page Layout
        <>
            <Vision></Vision>
            <div id="projects">
                <div className="text-center">
                    <div className="image">
                        <img src={getImageUrl('toe.jpg')}/>
                    </div>
                    <h3>ToE</h3>
                    <p>지구의 온도가 높아지면 어떤 일이 발생할까요? 어떻게 하면 지구의 온도를 낮출 수 있을까요?</p>
                    <div className="tags">
                        <span>Game</span><span>Environment</span>
                    </div>
                </div>
                <div className="text-center">
                    <div className="image">
                        <img src={getImageUrl('today.png')}/>
                    </div>
                    <h3>To.Day</h3>
                    <p>당신의 오늘을 더 풍성하게, 그리고 자원을 더 오래 써보세요.</p>
                    <div className="tags">
                        <span>Make</span><span>Environment</span>
                    </div>
                </div>
                <div className="text-center">
                    <div className="image">
                        <img src={getImageUrl('loc.png')}/>
                    </div>
                    <h3>
                        <img src="https://alji.ai/assets/logos/alji_logo_white.svg" style={{height:16}} alt="ALJI.AI"/>
                    </h3>
                    <p>지역을 공부하고, 해당 지역 기반 청사진을 제시합니다.</p>
                    <div className="tags">
                        <span>AI</span><span>Local</span><span>Branding</span>
                    </div>
                </div>
            </div>

            <Topic></Topic>

            <section id="social" className="text-center" style={{margin:'50px auto'}}>
                <a href="https://www.instagram.com/bepul.jang/" target="_blank">Instagram</a> 
                <a href="https://bepuljang.tistory.com/" target="_blank">Blog</a> 
                <a href="https://www.linkedin.com/company/bepuljang/" target="_blank">LinkedIn</a>
                <a href="https://www.youtube.com/@BepulJang-Creative" target="_blank">Youtube</a>
            </section>
        </>
    )
}

export default Main