import './App.css';
import { GlobalProvider } from './GlobalProvider';
import { SiteHeader, SiteBody, SiteFooter} from './common/';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {Main, About, Projects, Study} from './pages/';


const App = () => {
  return (
    <BrowserRouter>
      <GlobalProvider>
        <div id="site-container">
          <SiteHeader></SiteHeader>
          <SiteBody>
            <Routes>
                <Route path="/" element={<Main/>}></Route>
                <Route path="/about" element={<About/>}></Route>
                <Route path="/projects" element={<Projects/>}></Route>
                <Route path="/study" element={<Study/>}></Route>
            </Routes>
          </SiteBody>
          <SiteFooter></SiteFooter>
        </div>
      </GlobalProvider>
    </BrowserRouter>
  );
}

export default App;