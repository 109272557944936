import React from "react"
import BasicSection from "../components/BasicSection"

const Topic = () => {
    return (
        <section id="topic" style={{padding:10, display:"flex"}}>
            <div style={{margin:'auto'}}>
                <div id="topics">
                    <div>
                        <h3>Speculative Design</h3>
                        <p>사변적 디자인</p>
                    </div>
                    <div>
                        <h3>Design Development from AI</h3>
                        <p>AI를 이용한 디자인 구현</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Topic