import React, { createContext, useState, useContext, useEffect } from "react";

const GlobalContext = createContext();

export const GlobalProvider = (props) => {

    // Screen Width, Device, Language, Theme
    const screenWidth = window.innerWidth
    const [siteTheme, setSiteTheme] = useState('light')
    const [strLang, setStrLang] = useState('kr')

    useEffect(
        (()=> console.log("Current Language: " + strLang)), [strLang]
    )

    return(
        <GlobalContext.Provider value={{
            screenWidth, siteTheme, strLang, setSiteTheme, setStrLang
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

export const useGlobal = () => useContext(GlobalContext);