import React from "react";

const Page = () => {
    return (
        // Page Layout
        <>
            Projects
        </>
    )
}

export default Page