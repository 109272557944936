import React from "react"
import { Link } from "react-router-dom"
import { ReactComponent as Signature } from "../assets/imgs/signature.svg"

const SiteHeader = () => {
    return (
        <header id="site-header">
            <span className="zero-height">
            <Link to='/'>
                <Signature style={{width:'auto', height:34}}/>
            </Link>
            </span>     
        </header>
    )
}

export default SiteHeader