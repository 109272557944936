import React from "react";

const Page = () => {
    return (
        // Page Layout
        <>
            Study
        </>
    )
}

export default Page