import React from "react"

const SiteFooter = () => {
    return (
        <footer id="site-footer">
            <span id="copyright">
                BEPULJANG ⓒ 2021 All rights Preserved.
            </span>
            <span>
                <a href="https://alji.ai" target="_blank">
                    <img src="https://alji.ai/assets/logos/alji_logo_white.svg" style={{height:12}} alt="ALJI.AI"/>
                </a>
            </span>
        </footer>
    )
}

export default SiteFooter